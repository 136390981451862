define("ember-validations/validators/local/exclusion", ["exports", "@ember/utils", "@ember/object", "jquery", "ember-validations/validators/base", "ember-validations/messages"], function (_exports, _utils, _object, _jquery, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inArray
  } = _jquery.default;

  var _default = _exports.default = _base.default.extend({
    init() {
      this._super(...arguments);

      if (this.options.constructor === Array) {
        (0, _object.set)(this, 'options', {
          'in': this.options
        });
      }

      if (this.options.message === undefined) {
        (0, _object.set)(this, 'options.message', _messages.default.render('exclusion', this.options));
      }
    },

    call() {
      /*jshint expr:true*/
      let lower;
      let upper;

      if ((0, _utils.isEmpty)((0, _object.get)(this.model, this.property))) {
        if (this.options.allowBlank === undefined) {
          this.errors.pushObject(this.options.message);
        }
      } else if (this.options['in']) {
        if (inArray((0, _object.get)(this.model, this.property), this.options['in']) !== -1) {
          this.errors.pushObject(this.options.message);
        }
      } else if (this.options.range) {
        lower = this.options.range[0];
        upper = this.options.range[1];

        if ((0, _object.get)(this.model, this.property) >= lower && (0, _object.get)(this.model, this.property) <= upper) {
          this.errors.pushObject(this.options.message);
        }
      }
    }

  });
});