define("ember-cli-chartist/components/chartist-chart", ["exports", "@ember/component", "@ember/debug", "@ember/object", "@ember/string"], function (_exports, _component, _debug, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  var _default = _component.default.extend({
    classNameBindings: Object.freeze(['ratio']),
    classNames: Object.freeze(['ct-chart']),
    // The ratio of the chart as it scales up/down in size.
    //
    // Available ratios:
    //
    // name              ratio
    // ct-square         1
    // ct-minor-second   15:16
    // ct-major-second   8:9
    // ct-minor-third    5:6
    // ct-major-third    4:5
    // ct-perfect-fourth 3:4
    // ct-perfect-fifth  2:3
    // ct-minor-sixth    5:8
    // ct-golden-section 1:1.618
    // ct-major-sixth    3:5
    // ct-minor-seventh  9:16
    // ct-major-seventh  8:15
    // ct-octave         1:2
    // ct-major-tenth    2:5
    // ct-major-eleventh 3:8
    // ct-major-twelfth  1:3
    // ct-double-octave  1:4
    ratio: 'ct-square',
    chart: null,
    data: null,
    options: null,
    responsiveOptions: null,
    type: null,
    updateOnData: true,
    // Before trying to do anything else, let's check to see if any necessary
    // attributes are missing or if anything else is fishy about attributes
    // provided to the component in the template.
    //
    // We're doing this to help ease people into this project. Instead of just
    // getting some "uncaught exception" we're hoping these error messages will
    // point them in the right direction.
    init: function init() {
      var data = this.data;
      (true && !(_typeof(data) === 'object' && data !== null) && (0, _debug.assert)('The value of the "data" attribute must be an object', _typeof(data) === 'object' && data !== null));

      this._super.apply(this, arguments);
    },
    chartType: (0, _object.computed)('type', function () {
      var type = this.type;
      (true && !(typeof type !== 'undefined' && type !== null) && (0, _debug.assert)('Invalid or missing "type" attribute', typeof type !== 'undefined' && type !== null));
      return (0, _string.capitalize)(type);
    }),
    // This is where the business happens. This will only run if checkForReqs
    // doesn't find any problems.
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chartType = this.chartType,
          data = this.data,
          element = this.element,
          options = this.options,
          responsiveOptions = this.responsiveOptions;
      var chart = new Chartist[chartType](element, data, options, responsiveOptions);
      this.set('chart', chart);
    },

    /* eslint-disable ember/no-observers */
    onData: (0, _object.observer)('data', function () {
      var chart = this.chart,
          data = this.data,
          options = this.options,
          updateOnData = this.updateOnData;

      if (updateOnData) {
        var opts = options || {};
        chart.update(data, opts);
      }
    })
    /* eslint-enable ember/no-observers */

  });

  _exports.default = _default;
});