define("ember-validations/mixin", ["exports", "@ember/array/proxy", "@ember/object/mixin", "rsvp", "@ember/object/computed", "@ember/array", "@ember/utils", "@ember/object", "@ember/debug", "ember-validations/errors", "ember-validations/validators/base", "@ember/application"], function (_exports, _proxy, _mixin, _rsvp, _computed, _array, _utils, _object, _debug, _errors, _base, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const setValidityMixin = _mixin.default.create({
    isValid: (0, _object.computed)('validators.@each.isValid', function () {
      let compactValidators = (0, _object.get)(this, 'validators').compact();
      let filteredValidators = compactValidators.filter(validator => !(0, _object.get)(validator, 'isValid'));
      return (0, _object.get)(filteredValidators, 'length') === 0;
    }),
    isInvalid: (0, _computed.not)('isValid')
  });

  const pushValidatableObject = function (model, property) {
    let content = (0, _object.get)(model, property);
    model.removeObserver(property, pushValidatableObject);

    if ((0, _array.isArray)(content)) {
      model.validators.pushObject(ArrayValidatorProxy.create({
        model,
        property,
        contentBinding: `model.${property}`
      }));
    } else {
      model.validators.pushObject(content);
    }
  };

  const lookupValidator = function (validatorName) {
    let owner = (0, _application.getOwner)(this);
    let service = owner.lookup('service:validations');
    let validators = [];
    let cache;

    if (service) {
      cache = (0, _object.get)(service, 'cache');
    } else {
      cache = {};
    }

    if (cache[validatorName]) {
      validators = validators.concat(cache[validatorName]);
    } else {
      let local = owner.resolveRegistration(`validator:local/${validatorName}`);
      let remote = owner.resolveRegistration(`validator:remote/${validatorName}`);

      if (local || remote) {
        validators = validators.concat([local, remote]);
      } else {
        let base = owner.resolveRegistration(`validator:${validatorName}`);

        if (base) {
          validators = validators.concat([base]);
        } else {
          local = owner.resolveRegistration(`ember-validations@validator:local/${validatorName}`);
          remote = owner.resolveRegistration(`ember-validations@validator:remote/${validatorName}`);

          if (local || remote) {
            validators = validators.concat([local, remote]);
          }
        }
      }

      cache[validatorName] = validators;
    }

    (0, _debug.warn)(`Could not find the "${validatorName}" validator.`, (0, _utils.isPresent)(validators), {
      id: 'ember-validations.faild-to-find-validator'
    });
    return validators;
  };

  const ArrayValidatorProxy = _proxy.default.extend(setValidityMixin, {
    init() {
      this._validate();
    },

    validate() {
      return this._validate();
    },

    _validate() {
      let promises = (0, _object.get)(this, 'content').invoke('_validate').without(undefined);
      return (0, _rsvp.all)(promises);
    },

    validators: (0, _computed.alias)('content')
  });

  var _default = _exports.default = _mixin.default.create(setValidityMixin, {
    init() {
      this._super(...arguments);

      this.errors = _errors.default.create();
      this.dependentValidationKeys = {};
      this.validators = (0, _array.A)();

      if ((0, _object.get)(this, 'validations') === undefined) {
        this.validations = {};
      }

      this.buildValidators();
      this.validators.forEach(validator => {
        validator.addObserver('errors.[]', this, function (sender) {
          let errors = (0, _array.A)();
          this.validators.forEach(validator => {
            if (validator.property === sender.property) {
              errors.addObjects(validator.errors);
            }
          });
          (0, _object.set)(this, `errors.${sender.property}`, errors);
        });
      });

      this._validate();
    },

    buildValidators() {
      let property;

      for (property in this.validations) {
        if (this.validations[property].constructor === Object) {
          this.buildRuleValidator(property);
        } else {
          this.buildObjectValidator(property);
        }
      }
    },

    buildRuleValidator(property) {
      let pushValidator = (validator, validatorName) => {
        if (validator) {
          this.validators.pushObject(validator.create({
            model: this,
            property,
            options: this.validations[property][validatorName]
          }));
        }
      };

      if (this.validations[property].callback) {
        this.validations[property] = {
          inline: this.validations[property]
        };
      }

      let createInlineClass = callback => {
        return _base.default.extend({
          call() {
            let errorMessage = this.callback.call(this);

            if (errorMessage) {
              this.errors.pushObject(errorMessage);
            }
          },

          callback
        });
      };

      Object.keys(this.validations[property]).forEach(validatorName => {
        if (validatorName === 'inline') {
          let validator = createInlineClass(this.validations[property][validatorName].callback);
          pushValidator(validator, validatorName);
        } else if (this.validations[property].hasOwnProperty(validatorName)) {
          lookupValidator.call(this, validatorName).forEach(validator => {
            return pushValidator.call(this, validator, validatorName);
          });
        }
      });
    },

    buildObjectValidator(property) {
      if ((0, _utils.isNone)((0, _object.get)(this, property))) {
        this.addObserver(property, this, pushValidatableObject);
      } else {
        pushValidatableObject(this, property);
      }
    },

    validate() {
      return this._validate().then(vals => {
        let errors = (0, _object.get)(this, 'errors');

        if (vals.indexOf(false) > -1) {
          return (0, _rsvp.reject)(errors);
        }

        return errors;
      });
    },

    _validate() {
      let promises = this.validators.invoke('_validate').without(undefined);
      return (0, _rsvp.all)(promises);
    }

  });
});