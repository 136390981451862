define("ember-validations/validators/local/format", ["exports", "@ember/utils", "@ember/object", "ember-validations/validators/base", "ember-validations/messages"], function (_exports, _utils, _object, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _base.default.extend({
    init() {
      this._super(...arguments);

      if (this.options.constructor === RegExp) {
        (0, _object.set)(this, 'options', {
          'with': this.options
        });
      }

      if (this.options.message === undefined) {
        (0, _object.set)(this, 'options.message', _messages.default.render('invalid', this.options));
      }
    },

    call() {
      if ((0, _utils.isEmpty)((0, _object.get)(this.model, this.property))) {
        if (this.options.allowBlank === undefined) {
          this.errors.pushObject(this.options.message);
        }
      } else if (this.options['with'] && !this.options['with'].test((0, _object.get)(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      } else if (this.options.without && this.options.without.test((0, _object.get)(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      }
    }

  });
});