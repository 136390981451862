define("ember-validations/validators/local/presence", ["exports", "@ember/object", "@ember/utils", "ember-validations/validators/base", "ember-validations/messages"], function (_exports, _object, _utils, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _base.default.extend({
    init() {
      this._super(...arguments);
      /*jshint expr:true*/


      if (this.options === true) {
        this.options = {};
      }

      if (this.options.message === undefined) {
        this.options.message = _messages.default.render('blank', this.options);
      }
    },

    call() {
      if ((0, _utils.isBlank)((0, _object.get)(this.model, this.property))) {
        this.errors.pushObject(this.options.message);
      }
    }

  });
});