define("@ember/routing/lib/utils", ["exports", "ember-babel", "@ember/-internals/metal", "@ember/-internals/owner", "@ember/debug", "@ember/engine/instance", "router_js"], function (_exports, _emberBabel, _metal, _owner, _debug, _instance, _router_js) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateCacheKey = calculateCacheKey;
  _exports.deprecateTransitionMethods = deprecateTransitionMethods;
  _exports.extractRouteArgs = extractRouteArgs;
  _exports.getActiveTargetName = getActiveTargetName;
  _exports.normalizeControllerQueryParams = normalizeControllerQueryParams;
  _exports.prefixRouteNameArg = prefixRouteNameArg;
  _exports.resemblesURL = resemblesURL;
  _exports.shallowEqual = shallowEqual;
  _exports.stashParamNames = stashParamNames;
  var ALL_PERIODS_REGEX = /\./g;

  function extractRouteArgs(args) {
    // SAFETY: This should just be the same thing
    args = args.slice();
    var possibleOptions = args[args.length - 1];
    var queryParams;

    if (isRouteOptions(possibleOptions)) {
      args.pop(); // Remove options

      queryParams = possibleOptions.queryParams;
    } else {
      queryParams = {};
    }

    var routeName;

    if (typeof args[0] === 'string') {
      routeName = args.shift(); // We just checked this!

      (true && !(typeof routeName === 'string') && (0, _debug.assert)('routeName is a string', typeof routeName === 'string'));
    } // SAFTEY: We removed the name and options if they existed, only models left.


    var models = args;
    return {
      routeName: routeName,
      models: models,
      queryParams: queryParams
    };
  }

  function getActiveTargetName(router) {
    var routeInfos = router.activeTransition ? router.activeTransition[_router_js.STATE_SYMBOL].routeInfos : router.state.routeInfos;
    var lastRouteInfo = routeInfos[routeInfos.length - 1];
    (true && !(lastRouteInfo) && (0, _debug.assert)('has last route info', lastRouteInfo));
    return lastRouteInfo.name;
  }

  function stashParamNames(router, routeInfos) {
    if (routeInfos['_namesStashed']) {
      return;
    } // This helper exists because router.js/route-recognizer.js awkwardly
    // keeps separate a routeInfo's list of parameter names depending
    // on whether a URL transition or named transition is happening.
    // Hopefully we can remove this in the future.


    var routeInfo = routeInfos[routeInfos.length - 1];
    (true && !(routeInfo) && (0, _debug.assert)('has route info', routeInfo));
    var targetRouteName = routeInfo.name;

    var recogHandlers = router._routerMicrolib.recognizer.handlersFor(targetRouteName);

    var dynamicParent;

    for (var _i = 0; _i < routeInfos.length; ++_i) {
      var _routeInfo4 = routeInfos[_i];
      (true && !(_routeInfo4) && (0, _debug.assert)('has route info', _routeInfo4));
      var _names3 = recogHandlers[_i].names;

      if (_names3.length) {
        dynamicParent = _routeInfo4;
      }

      _routeInfo4['_names'] = _names3;
      var _route3 = _routeInfo4.route; // SAFETY: This cast should be idential. I don't understand why it is needed.

      _route3._stashNames(_routeInfo4, dynamicParent);
    }

    routeInfos['_namesStashed'] = true;
  }

  function _calculateCacheValuePrefix(prefix, part) {
    // calculates the dot separated sections from prefix that are also
    // at the start of part - which gives us the route name
    // given : prefix = site.article.comments, part = site.article.id
    //      - returns: site.article (use get(values[site.article], 'id') to get the dynamic part - used below)
    // given : prefix = site.article, part = site.article.id
    //      - returns: site.article. (use get(values[site.article], 'id') to get the dynamic part - used below)
    var prefixParts = prefix.split('.');
    var currPrefix = '';

    for (var _i2 = 0; _i2 < prefixParts.length; _i2++) {
      var _currPart3 = prefixParts.slice(0, _i2 + 1).join('.');

      if (part.indexOf(_currPart3) !== 0) {
        break;
      }

      currPrefix = _currPart3;
    }

    return currPrefix;
  }
  /*
    Stolen from Controller
  */


  function calculateCacheKey(prefix, parts, values) {
    if (parts === void 0) {
      parts = [];
    }

    var suffixes = '';

    for (var _iterator = (0, _emberBabel.createForOfIteratorHelperLoose)(parts), _step; !(_step = _iterator()).done;) {
      var _part3 = _step.value;

      var _cacheValuePrefix3 = _calculateCacheValuePrefix(prefix, _part3);

      var _value3 = void 0;

      if (values) {
        if (_cacheValuePrefix3 && _cacheValuePrefix3 in values) {
          var _partRemovedPrefix3 = _part3.indexOf(_cacheValuePrefix3) === 0 ? _part3.substring(_cacheValuePrefix3.length + 1) : _part3;

          _value3 = (0, _metal.get)(values[_cacheValuePrefix3], _partRemovedPrefix3);
        } else {
          _value3 = (0, _metal.get)(values, _part3);
        }
      }

      suffixes += "::" + _part3 + ":" + _value3;
    }

    return prefix + suffixes.replace(ALL_PERIODS_REGEX, '-');
  }
  /*
    Controller-defined query parameters can come in three shapes:
  
    Array
      queryParams: ['foo', 'bar']
    Array of simple objects where value is an alias
      queryParams: [
        {
          'foo': 'rename_foo_to_this'
        },
        {
          'bar': 'call_bar_this_instead'
        }
      ]
    Array of fully defined objects
      queryParams: [
        {
          'foo': {
            as: 'rename_foo_to_this'
          },
        }
        {
          'bar': {
            as: 'call_bar_this_instead',
            scope: 'controller'
          }
        }
      ]
  
    This helper normalizes all three possible styles into the
    'Array of fully defined objects' style.
  */


  function normalizeControllerQueryParams(queryParams) {
    var qpMap = {};

    for (var _iterator2 = (0, _emberBabel.createForOfIteratorHelperLoose)(queryParams), _step2; !(_step2 = _iterator2()).done;) {
      var _queryParam3 = _step2.value;
      accumulateQueryParamDescriptors(_queryParam3, qpMap);
    }

    return qpMap;
  }

  function accumulateQueryParamDescriptors(_desc, accum) {
    var _ref;

    var desc = typeof _desc === 'string' ? (_ref = {}, _ref[_desc] = {
      as: null
    }, _ref) : _desc;

    for (var _key in desc) {
      if (!Object.prototype.hasOwnProperty.call(desc, _key)) {
        return;
      }

      var _singleDesc6 = desc[_key];

      var _singleDesc7 = typeof _singleDesc6 === 'string' ? {
        as: _singleDesc6
      } : _singleDesc6;

      var _partialVal3 = accum[_key] || {
        as: null,
        scope: 'model'
      };

      var _val3 = Object.assign(Object.assign({}, _partialVal3), _singleDesc7);

      accum[_key] = _val3;
    }
  }
  /*
    Check if a routeName resembles a url instead
  
    @private
  */


  function resemblesURL(str) {
    return typeof str === 'string' && (str === '' || str[0] === '/');
  }
  /*
    Returns an arguments array where the route name arg is prefixed based on the mount point
  
    @private
  */


  function prefixRouteNameArg(route, args) {
    var routeName;
    var owner = (0, _owner.getOwner)(route);
    (true && !(owner instanceof _instance.default) && (0, _debug.assert)('Expected route to have EngineInstance as owner', owner instanceof _instance.default));
    var prefix = owner.mountPoint; // only alter the routeName if it's actually referencing a route.

    if (owner.routable && typeof args[0] === 'string') {
      routeName = args[0];

      if (resemblesURL(routeName)) {
        throw new Error('Programmatic transitions by URL cannot be used within an Engine. Please use the route name instead.');
      } else {
        routeName = prefix + "." + routeName;
        args[0] = routeName;
      }
    }

    return args;
  }

  function shallowEqual(a, b) {
    var aCount = 0;
    var bCount = 0;

    for (var _kA in a) {
      if (Object.prototype.hasOwnProperty.call(a, _kA)) {
        if (a[_kA] !== b[_kA]) {
          return false;
        }

        aCount++;
      }
    }

    for (var _kB in b) {
      if (Object.prototype.hasOwnProperty.call(b, _kB)) {
        bCount++;
      }
    }

    return aCount === bCount;
  }

  function deprecateTransitionMethods(frameworkClass, methodName) {
    (true && !(false) && (0, _debug.deprecate)("Calling " + methodName + " on a " + frameworkClass + " is deprecated. Use the RouterService instead.", false, {
      id: 'routing.transition-methods',
      for: 'ember-source',
      since: {
        available: '3.26.0',
        enabled: '3.26.0'
      },
      until: '5.0.0',
      url: 'https://deprecations.emberjs.com/v3.x/#toc_routing-transition-methods'
    }));
  }

  function isRouteOptions(value) {
    if (value && typeof value === 'object') {
      var _qps3 = value.queryParams;

      if (_qps3 && typeof _qps3 === 'object') {
        return Object.keys(_qps3).every(function (k) {
          return typeof k === 'string';
        });
      }
    }

    return false;
  }
});