define("@ember/object/compat", ["exports", "@ember/-internals/metal", "@ember/debug", "@glimmer/validator"], function (_exports, _metal, _debug, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dependentKeyCompat = dependentKeyCompat;

  var wrapGetterSetter = function wrapGetterSetter(target, key, desc) {
    var originalGet = desc.get;
    (true && !((0, _metal.descriptorForProperty)(target, key) === undefined) && (0, _debug.assert)('You attempted to use @dependentKeyCompat on a property that already has been decorated with either @computed or @tracked. @dependentKeyCompat is only necessary for native getters that are not decorated with @computed.', (0, _metal.descriptorForProperty)(target, key) === undefined));

    if (originalGet !== undefined) {
      desc.get = function () {
        var _this = this;

        var propertyTag = (0, _validator.tagFor)(this, key);
        var ret;
        var tag = (0, _validator.track)(function () {
          ret = originalGet.call(_this);
        });
        (0, _validator.updateTag)(propertyTag, tag);
        (0, _validator.consumeTag)(tag);
        return ret;
      };
    }

    return desc;
  };

  function dependentKeyCompat() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if ((0, _metal.isElementDescriptor)(args)) {
      var _target = args[0],
          _key2 = args[1],
          _desc2 = args[2];
      (true && !(_desc2 != null && (typeof _desc2.get === 'function' || typeof _desc2.set === 'function')) && (0, _debug.assert)('The @dependentKeyCompat decorator must be applied to getters/setters when used in native classes', _desc2 != null && (typeof _desc2.get === 'function' || typeof _desc2.set === 'function')));
      return wrapGetterSetter(_target, _key2, _desc2);
    } else {
      var _desc5 = args[0];
      (true && !(function (value) {
        if (value && typeof value === 'object') {
          var _cast5 = value;
          return (_cast5.configurable === undefined || _cast5.configurable === false || _cast5.configurable === true) && (_cast5.enumerable === undefined || _cast5.enumerable === false || _cast5.enumerable === true) && (_cast5.writable === undefined || _cast5.writable === false || _cast5.writable === true) && (_cast5.get === undefined || typeof _cast5.get === 'function') && (_cast5.set === undefined || typeof _cast5.set === 'function');
        }

        return false;
      }(_desc5)) && (0, _debug.assert)('expected valid PropertyDescriptor', function (value) {
        if (value && typeof value === 'object') {
          var _cast5 = value;
          return (_cast5.configurable === undefined || _cast5.configurable === false || _cast5.configurable === true) && (_cast5.enumerable === undefined || _cast5.enumerable === false || _cast5.enumerable === true) && (_cast5.writable === undefined || _cast5.writable === false || _cast5.writable === true) && (_cast5.get === undefined || typeof _cast5.get === 'function') && (_cast5.set === undefined || typeof _cast5.set === 'function');
        }

        return false;
      }(_desc5)));

      var _decorator3 = function _decorator(target, key, _desc, _meta, isClassicDecorator) {
        (true && !(isClassicDecorator) && (0, _debug.assert)('The @dependentKeyCompat decorator may only be passed a method when used in classic classes. You should decorate getters/setters directly in native classes', isClassicDecorator));
        (true && !(typeof _desc5.get === 'function' || typeof _desc5.set === 'function') && (0, _debug.assert)('The dependentKeyCompat() decorator must be passed a getter or setter when used in classic classes', typeof _desc5.get === 'function' || typeof _desc5.set === 'function'));
        return wrapGetterSetter(target, key, _desc5);
      };

      (0, _metal.setClassicDecorator)(_decorator3);
      return _decorator3;
    }
  }

  (0, _metal.setClassicDecorator)(dependentKeyCompat);
});