define("ember-cli-app-version/initializer-factory", ["exports", "@ember/-internals/metal"], function (_exports, _metal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initializerFactory;

  function initializerFactory(name, version) {
    var registered = false;
    return function () {
      if (!registered && name && version) {
        _metal.libraries.register(name, version);

        registered = true;
      }
    };
  }
});