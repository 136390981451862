define("ember-validations/validators/local/confirmation", ["exports", "@ember/utils", "@ember/object", "ember-validations/validators/base", "ember-validations/messages"], function (_exports, _utils, _object, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _base.default.extend({
    init() {
      this.originalProperty = this.property;
      this.property = `${this.property}Confirmation`;

      this._super(...arguments);

      this.dependentValidationKeys.pushObject(this.originalProperty);
      /*jshint expr:true*/

      if (this.options === true) {
        (0, _object.set)(this, 'options', {
          attribute: this.originalProperty
        });
        (0, _object.set)(this, 'options', {
          message: _messages.default.render('confirmation', this.options)
        });
      }
    },

    call() {
      let original = (0, _object.get)(this.model, this.originalProperty);
      let confirmation = (0, _object.get)(this.model, this.property);

      if ((0, _utils.isPresent)(original) || (0, _utils.isPresent)(confirmation)) {
        if (original !== confirmation) {
          this.errors.pushObject(this.options.message);
        }
      }
    }

  });
});