define("ember-validations/index", ["exports", "ember-validations/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.validator = validator;

  var _default = _exports.default = _mixin.default;

  function validator(callback) {
    return {
      callback
    };
  }
});