define("ember-validations/errors", ["exports", "@ember/array", "@ember/object"], function (_exports, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _object.default.extend({
    unknownProperty(property) {
      (0, _object.set)(this, property, (0, _array.A)());
      return (0, _object.get)(this, property);
    }

  });
});