define("ember-validations/validators/local/numericality", ["exports", "@ember/object", "@ember/utils", "jquery", "ember-validations/validators/base", "ember-validations/messages", "ember-validations/patterns"], function (_exports, _object, _utils, _jquery, _base, _messages, _patterns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inArray
  } = _jquery.default;

  var _default = _exports.default = _base.default.extend({
    init() {
      /*jshint expr:true*/
      let index;
      let keys;
      let key;

      this._super(...arguments);

      if (this.options === true) {
        this.options = {};
      } else if (this.options.constructor === String) {
        key = this.options;
        this.options = {};
        this.options[key] = true;
      }

      if (this.options.messages === undefined || this.options.messages.numericality === undefined) {
        this.options.messages = this.options.messages || {};
        this.options.messages.numericality = _messages.default.render('notANumber', this.options);
      }

      if (this.options.onlyInteger !== undefined && this.options.messages.onlyInteger === undefined) {
        this.options.messages.onlyInteger = _messages.default.render('notAnInteger', this.options);
      }

      keys = Object.keys(this.CHECKS).concat(['odd', 'even']);

      for (index = 0; index < keys.length; index++) {
        key = keys[index];
        let prop = this.options[key]; // I have no idea what the hell is going on here. This seems to do nothing.
        // The observer's key is being set to the values in the options hash?

        if (key in this.options && isNaN(prop)) {
          this.model.addObserver(prop, this, this._validate);
        }

        if (prop !== undefined && this.options.messages[key] === undefined) {
          if (inArray(key, Object.keys(this.CHECKS)) !== -1) {
            this.options.count = prop;
          }

          this.options.messages[key] = _messages.default.render(key, this.options);

          if (this.options.count !== undefined) {
            delete this.options.count;
          }
        }
      }
    },

    CHECKS: {
      equalTo: '===',
      greaterThan: '>',
      greaterThanOrEqualTo: '>=',
      lessThan: '<',
      lessThanOrEqualTo: '<='
    },

    call() {
      let check;
      let checkValue;
      let comparisonResult;

      if ((0, _utils.isEmpty)((0, _object.get)(this.model, this.property))) {
        if (this.options.allowBlank === undefined) {
          this.errors.pushObject(this.options.messages.numericality);
        }
      } else if (!_patterns.default.numericality.test((0, _object.get)(this.model, this.property))) {
        this.errors.pushObject(this.options.messages.numericality);
      } else if (this.options.onlyInteger === true && !/^[+\-]?\d+$/.test((0, _object.get)(this.model, this.property))) {
        this.errors.pushObject(this.options.messages.onlyInteger);
      } else if (this.options.odd && parseInt((0, _object.get)(this.model, this.property), 10) % 2 === 0) {
        this.errors.pushObject(this.options.messages.odd);
      } else if (this.options.even && parseInt((0, _object.get)(this.model, this.property), 10) % 2 !== 0) {
        this.errors.pushObject(this.options.messages.even);
      } else {
        for (check in this.CHECKS) {
          if (this.options[check] === undefined) {
            continue;
          }

          if (!isNaN(parseFloat(this.options[check])) && isFinite(this.options[check])) {
            checkValue = this.options[check];
          } else if ((0, _object.get)(this.model, this.options[check]) !== undefined) {
            checkValue = (0, _object.get)(this.model, this.options[check]);
          }

          comparisonResult = this.compare((0, _object.get)(this.model, this.property), checkValue, this.CHECKS[check]);

          if (!comparisonResult) {
            this.errors.pushObject(this.options.messages[check]);
          }
        }
      }
    }

  });
});