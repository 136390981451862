define("ember-validations/patterns", ["exports", "@ember/application/namespace"], function (_exports, _namespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _namespace.default.create({
    numericality: /^(-|\+)?(?:(?:(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d*)?)|(?:\.\d+))$/,
    blank: /^\s*$/
  });
});