define("ember-validations/validators/local/acceptance", ["exports", "@ember/object", "ember-validations/validators/base", "ember-validations/messages"], function (_exports, _object, _base, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _base.default.extend({
    init() {
      this._super(...arguments);
      /*jshint expr:true*/


      if (this.options === true) {
        (0, _object.set)(this, 'options', {});
      }

      if (this.options.message === undefined) {
        (0, _object.set)(this, 'options.message', _messages.default.render('accepted', this.options));
      }
    },

    call() {
      if (this.options.accept) {
        if ((0, _object.get)(this.model, this.property) !== this.options.accept) {
          this.errors.pushObject(this.options.message);
        }
      } else if ((0, _object.get)(this.model, this.property) !== '1' && (0, _object.get)(this.model, this.property) !== 1 && (0, _object.get)(this.model, this.property) !== true) {
        this.errors.pushObject(this.options.message);
      }
    }

  });
});