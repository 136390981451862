define("@ember/error/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The JavaScript Error object used by Ember.assert.
  
    @class Error
    @namespace Ember
    @extends Error
    @constructor
    @public
    @deprecated
  */
  var EmberError;

  if (true
  /* DEBUG */
  ) {
    // eslint-disable-next-line no-inner-declarations
    var _EmberDebugConstructor2 = function EmberDebugConstructor(message) {
      (true && !(false) && (0, _debug.deprecate)('The @ember/error package merely re-exported the native Error and is deprecated. Please use a native Error directly instead.', false, {
        id: 'deprecate-ember-error',
        until: '5.0.0',
        url: 'https://deprecations.emberjs.com/v4.x/#toc_deprecate-ember-error',
        for: 'ember-source',
        since: {
          available: '4.10.0',
          enabled: '4.10.0'
        }
      }));
      return new Error(message);
    };

    _EmberDebugConstructor2.prototype = Error.prototype; // SAFETY: We need this cast since our EmberDebugConstructor doesn't define a type for `new` even though it will work with `new`.

    EmberError = _EmberDebugConstructor2;
  } else {
    EmberError = Error;
  }

  var _default = EmberError;
  _exports.default = _default;
});