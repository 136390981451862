define("ember-validations/validators/base", ["exports", "@ember/array", "rsvp", "@ember/object/computed", "@ember/object/evented", "@ember/object"], function (_exports, _array, _rsvp, _computed, _evented, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _exports.default = _object.default.extend({
    init() {
      this._super(...arguments);

      (0, _object.set)(this, 'errors', (0, _array.A)());
      this.dependentValidationKeys = (0, _array.A)();
      this.conditionals = {
        'if': (0, _object.get)(this, 'options.if'),
        unless: (0, _object.get)(this, 'options.unless')
      };
      this.model.addObserver(this.property, this, this._validate);
    },

    addObserversForDependentValidationKeys: (0, _evented.on)('init', function () {
      this.dependentValidationKeys.forEach(function (key) {
        this.model.addObserver(key, this, this._validate);
      }, this);
    }),
    pushConditionalDependentValidationKeys: (0, _evented.on)('init', function () {
      (0, _array.A)(['if', 'unless']).forEach(conditionalKind => {
        let conditional = this.conditionals[conditionalKind];

        if (typeof conditional === 'string' && typeof this.model[conditional] !== 'function') {
          this.dependentValidationKeys.pushObject(conditional);
        }
      });
    }),
    pushDependentValidationKeyToModel: (0, _evented.on)('init', function () {
      let model = (0, _object.get)(this, 'model');

      if (model.dependentValidationKeys[this.property] === undefined) {
        model.dependentValidationKeys[this.property] = (0, _array.A)();
      }

      model.dependentValidationKeys[this.property].addObjects(this.dependentValidationKeys);
    }),

    call() {
      throw 'Not implemented!';
    },

    unknownProperty(key) {
      let model = (0, _object.get)(this, 'model');

      if (model) {
        return (0, _object.get)(model, key);
      }
    },

    isValid: (0, _computed.empty)('errors.[]'),
    isInvalid: (0, _computed.not)('isValid'),

    validate() {
      return this._validate().then(success => {
        // Convert validation failures to rejects.
        let errors = (0, _object.get)(this, 'model.errors');

        if (success) {
          return errors;
        } else {
          return (0, _rsvp.reject)(errors);
        }
      });
    },

    _validate: (0, _evented.on)('init', function () {
      this.errors.clear();

      if (this.canValidate()) {
        this.call();
      }

      if ((0, _object.get)(this, 'isValid')) {
        return (0, _rsvp.resolve)(true);
      } else {
        return (0, _rsvp.resolve)(false);
      }
    }),

    canValidate() {
      if (typeof this.conditionals === 'object') {
        if (this.conditionals['if']) {
          if (typeof this.conditionals['if'] === 'function') {
            return this.conditionals['if'](this.model, this.property);
          } else if (typeof this.conditionals['if'] === 'string') {
            if (typeof this.model[this.conditionals['if']] === 'function') {
              return this.model[this.conditionals['if']]();
            } else {
              return (0, _object.get)(this.model, this.conditionals['if']);
            }
          }
        } else if (this.conditionals.unless) {
          if (typeof this.conditionals.unless === 'function') {
            return !this.conditionals.unless(this.model, this.property);
          } else if (typeof this.conditionals.unless === 'string') {
            if (typeof this.model[this.conditionals.unless] === 'function') {
              return !this.model[this.conditionals.unless]();
            } else {
              return !(0, _object.get)(this.model, this.conditionals.unless);
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    compare(a, b, operator) {
      switch (operator) {
        case '==':
          return a == b;
        // jshint ignore:line

        case '===':
          return a === b;

        case '>=':
          return a >= b;

        case '<=':
          return a <= b;

        case '>':
          return a > b;

        case '<':
          return a < b;

        default:
          return false;
      }
    }

  });
});